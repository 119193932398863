import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import "twin.macro"
import { siteTheme } from "theme"
import { GoogleFonts, CustomFonts } from "theme/global/fonts.css"
import { IGoogleFont, ICustomFont } from "theme/global/fonts.types"
import { GlobalStyles } from "theme/global/global.css"
import { useViewport } from "hooks/useViewport"
import { ViewportContext, NavigationContext } from "context"
import { ILayoutProps } from "./layout.types"
import { OffCanvasNav } from "./OffCanvasNav"
import { Header } from "./Header"
import { NavigationToggle } from "lib/Navigation/Toggle"
import { NavigationUntoggle } from "lib/Navigation/Untoggle"
import { TapBar } from "lib/Navigation/TapBar"
import { Footer } from "./Footer"
import { frb, brb, loc3, loc4, about, instagram, facebook } from "./data"
import { Helmet } from "react-helmet";

const Oswald: IGoogleFont = {
  title: "Oswald",
  weights: ["600"],
}

const Lato: IGoogleFont = {
  title: "Lato",
  weights: ["300", "900"],
}

const StyledMain = styled.main``

export const Layout: React.FC<ILayoutProps> = ({ children, ...props }) => {
  const viewport = useViewport()
  const [navToggled, setNavToggled] = useState<boolean>(false)

  function onNavToggleHandler(e: React.SyntheticEvent) {
    e.preventDefault()
    setNavToggled(prev => !prev)
  }

  const navigationData = useStaticQuery(graphql`
    query NavigationQuery {
      allSanitySiteSettings {
        edges {
          node {
            siteConfig {
              _rawFooterLeft(resolveReferences: { maxDepth: 10 })
              _rawFooterRight(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
      sanityNavigation {
        items {
          ... on SanityCta {
            _key
            _type
            ctaLink {
              _key
              external
              internal {
                slug {
                  current
                }
              }
            }
            themeColor
            ctaText
          }
          ... on SanityNavItem {
            _key
            _type
            navText
            documentRef {
              _id
              _key
              pageTitle
              slug {
                current
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={siteTheme}>
      <GoogleFonts fonts={[Oswald, Lato]} />
      <GlobalStyles />
      <Helmet>
        <script>
                    {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-NWG4QVF');
                    `}
                </script>
      </Helmet>
      <ViewportContext.Provider value={viewport}>
        <NavigationContext.Provider value={navToggled}>
          <StyledMain tw="relative min-h-screen" {...props}>
            <Header>
              <TapBar items={navigationData.sanityNavigation.items} />
              <NavigationToggle onClick={e => onNavToggleHandler(e)} />
            </Header>
            {children}
            <Footer
              columns={
                navigationData.allSanitySiteSettings.edges[0].node.siteConfig
              }
              about={about}
              locations={[frb, brb, loc3, loc4]}
              socialMediaLinks={[facebook, instagram]}
            />
          </StyledMain>
          <OffCanvasNav
            items={navigationData.sanityNavigation.items}
            untoggle={
              <NavigationUntoggle onClick={e => onNavToggleHandler(e)} />
            }
          >
            {process.env.GATSBY_ACTIVE_ENV !== "production" && (
              <>
                <span tw="text-gray-600 bg-gray-800 px-2 fixed bottom-0 left-0">
                  {`${viewport} - env: ${process.env.GATSBY_ACTIVE_ENV}`}
                </span>
              </>
            )}
          </OffCanvasNav>
        </NavigationContext.Provider>
      </ViewportContext.Provider>
    </ThemeProvider>
  )
}
