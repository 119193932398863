import React from "react"
import tw from 'twin.macro'
import { ISectionProps } from "./section.types"
import { Container } from "components/Container"
import styled from "styled-components"

/**
 * A simple wrapper around children, to perform common layout adjustments.
 * Edit available brandStyles in theme/index.ts
 */

const StyledSection = styled.section<ISectionProps>`
  ${({ themeColor, theme }) =>
    themeColor ? theme.brandStyles[themeColor] : theme.brandStyles.default}

  ${props => !props.removeBottomSpacing && tw`mb-20`}
`

export const Section: React.FC<ISectionProps> = ({
  themeColor,
  removeBottomSpacing,
  container,
  children,
  ...props
}) => {
  return (
    <StyledSection themeColor={themeColor} {...props} removeBottomSpacing={removeBottomSpacing}>
      {container ? <Container>{children}</Container> : children}
    </StyledSection>
  )
}
