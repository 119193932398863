import React from "react"
import "twin.macro"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import { Icon } from "lib/Icon"
// import { Location } from "components/Location"
import { IFooterProps } from "./footer.types"
import { Section } from "components/Section"
import { Container } from "components/Container"
import { SocialMediaLink } from "../Social"

const Angle = styled.svg`
  height: 200px;

  ${props => props.theme.media.tablet_portrait_up`
    height: 200px;
  `}
  ${props => props.theme.media.desktop_up`
    height: 200px;
  `}
`

const StyledBlockContent = styled(BlockContent)`
  h1 {
    font-family: Oswald, Impact, sans-serif;
    font-size: 1.625rem;
    margin-bottom: 1rem;
    margin-top: 4rem;
  }
  p {
    margin: 0;
    margin-bottom: 0;
  }
`

export const Footer: React.FC<IFooterProps> = ({
  about,
  locations,
  socialMediaLinks,
  columns,
  ...props
}) => {

  return (
    <footer tw="mt-10">
      <Angle
        tw="fill-current text-creme w-full z-0"
        fill="currentColor"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon points="0,0 100,50 100,100 0,100" />
      </Angle>
      <Section themeColor="creme" tw="mb-0 pb-20">
        <Container tw="grid gap-20 grid-cols-1 divide-gray-400 px-12 sm:divide-x-2  sm:grid-cols-2 md:grid-cols-3">
          <div className="about" tw="text-center">
            <Icon icon="logo" tw="w-9/12  mb-4" />
            <p>{about}</p>
          </div>
          <div tw="col-span-1 flex flex-col text-center sm:pl-20 sm:text-left lg:flex-row md:col-span-2">
            {locations && (
              <div
                className="locations"
                tw="grid grid-cols-1 gap-20 md:grid-cols-2"
              >
                <StyledBlockContent
                  blocks={columns._rawFooterLeft}
                  // {...props}
                />
                <StyledBlockContent
                  blocks={columns._rawFooterRight}
                  // {...props}
                />

                {/* {locations.map(location => (
                  <Location key={location.key} {...location} />
                ))} */}
              </div>
            )}
            {socialMediaLinks && (
              <div tw="flex mt-12 justify-between lg:items-end lg:flex-col lg:mt-0">
                <div className="social" tw="flex space-x-4">
                  {socialMediaLinks.map(link => (
                    <SocialMediaLink key={link.key} {...link} />
                  ))}
                </div>
                <a
                  href="https://www.findsmiley.dk/Sider/Search.aspx?k=de%20fyrretyve%20r%C3%B8vere"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/footerBadge.jpg" tw="w-20" />
                </a>
              </div>
            )}
          </div>
        </Container>
      </Section>
    </footer>
  )
}
