import { useState, useEffect } from "react"

export function useInnerHeight() {
  const [height, setHeight] = useState<number>(0)

  function handleViewportChange() {
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleViewportChange()
      window.addEventListener("resize", handleViewportChange, false)
    }
    return function cleanup() {
      window.removeEventListener("resize", handleViewportChange, false)
    }
  })

  return height
}
