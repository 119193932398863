import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import "twin.macro"
import { Button } from "lib/Button"
import { Icon } from "lib/Icon"
import { ITapBarProps } from "../navigation.types"

const StyledTapBar = styled.nav`
  position: sticky;
  top: 6rem;
  /* ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14%, 1fr));

    .item {
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.5rem 1rem;

        &.active {
          ${tw`text-yellow`};
        }

        &:focus,
        &.active {
        }

        svg {
          fill: currentColor;
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  } */
`

const TapBar: React.FC<ITapBarProps> = ({ items, children, ...props }) => {
  return (
    <StyledTapBar tw="px-16 hidden md:flex items-center" {...props}>
      <ul tw="py-4 flex justify-end space-x-16 items-baseline">
        {items.map((item, i) => {
            return (
              <li className="item" key={item._key}>
                {item._type === 'navItem' && (
                  <Link to={`/${item.documentRef.slug.current}`} tw="flex flex-col items-center space-y-2" className="group"
                >
                  <span tw="text-3xl font-semibold">{item.documentRef.pageTitle}</span>
                  <Icon tw="w-4 h-4 transition duration-100 ease-in-out transform group-hover:translate-y-1 group-hover:scale-150" icon="divider-tegn" />
                </Link>
                )}
                {item._type === 'cta' && item?.ctaLink?.internal?.slug?.current && item.ctaText && (
                  <Button themeColor={item.themeColor || 'yellow'}>
                    <Link to={`/${item.ctaLink.internal.slug.current}`} tw="">
                      {item.ctaText}
                    </Link>
                  </Button>
                )}
                {item._type === 'cta' && item?.ctaLink?.external && item.ctaText && (
                  <Button themeColor={item.themeColor || 'yellow'}>
                    <a href={item?.ctaLink?.external} tw="">
                      {item.ctaText}
                    </a>
                  </Button>
                )}
              </li>
            )
          }
        )}
      </ul>
    </StyledTapBar>
  )
}

export { TapBar }
