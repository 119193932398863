import React, { useContext } from "react"
import styled from "styled-components"
import "twin.macro"
import { Link } from "gatsby"
import { Button } from "lib/Button"
import { Icon } from "lib/Icon"
import { OffCanvas } from "lib/OffCanvas"
import { NavigationContext } from "context"
import { useLockBodyScroll } from "hooks/useLockBodyScroll"
import { useInnerHeight } from "hooks/useInnerHeight"
import { IOffCanvasNavProps } from "./offcanvasnav.types"

const StyledOffCanvasNavigation = styled.div`
  width: 100vw;
  overflow-y: scroll;

  .inner {
  }

  .nav {
  }
`

const OffCanvasNav: React.FC<IOffCanvasNavProps> = ({
  untoggle,
  items,
  children,
}) => {
  const toggled = useContext(NavigationContext)
  const innerHeight = useInnerHeight()
  useLockBodyScroll(toggled)
  return (
    <OffCanvas selector="#___offcanvas" show={toggled} direction="right">
      <StyledOffCanvasNavigation
        style={{ height: innerHeight }}
        tw="bg-white flex justify-center items-center"
      >
        {untoggle}
        <nav className="nav">
          <ul tw="py-4 flex flex-col justify-center items-center space-y-16">
            {items.map((item, i) => {
              return (
                <li className="item" key={item._key}>
                  {item._type === "navItem" && (
                    <Link
                      to={`/${item.documentRef.slug.current}`}
                      tw="flex flex-col items-center space-y-2"
                      className="group"
                    >
                      <span tw="text-3xl font-semibold">
                        {item.documentRef.pageTitle}
                      </span>
                      <Icon
                        tw="w-4 h-4 transition duration-100 ease-in-out transform group-hover:translate-y-1 group-hover:scale-150"
                        icon="divider-tegn"
                      />
                    </Link>
                  )}
                  {item._type === "cta" &&
                    item?.ctaLink?.internal?.slug?.current &&
                    item.ctaText && (
                      <Button themeColor={item.themeColor || "yellow"}>
                        <Link
                          to={`/${item.ctaLink.internal.slug.current}`}
                          tw=""
                        >
                          {item.ctaText}
                        </Link>
                      </Button>
                    )}
                  {item._type === "cta" &&
                    item?.ctaLink?.external &&
                    item.ctaText && (
                      <Button themeColor={item.themeColor || "yellow"}>
                        <a href={item?.ctaLink?.external} tw="">
                          {item.ctaText}
                        </a>
                      </Button>
                    )}
                </li>
              )
            })}
          </ul>
        </nav>
        <div className="inner">{children}</div>
      </StyledOffCanvasNavigation>
    </OffCanvas>
  )
}

export { OffCanvasNav }
