import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Headroom from "react-headroom"
import "twin.macro"
import { Icon } from "lib/Icon"
import { IHeaderProps } from "./header.types"
import { Container } from "components/Container"


const StyledHeader = styled.header`
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      display: block;

      svg {
        width: 10rem;
        height: 10rem;

        ${props => props.theme.media.tablet_landscape_up`
        width: 20rem;
        height: 20rem;
      `}
      }
    }
  }
`

const Header: React.FC<IHeaderProps> = ({ children, ...props }) => {
  return (
    <Headroom>
      <StyledHeader {...props} tw="bg-white">
        <Container className="inner" tw="flex items-center py-0">
          <Link className="logo" to="/">
            <Icon icon="logo" />
          </Link>
          <span className="menu" tw="w-full flex justify-end">{children}</span>
        </Container>
      </StyledHeader>
    </Headroom>
  )
}

export { Header }
