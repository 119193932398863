import { useState, useEffect } from "react"
import { Viewport } from "./hooks.types"

export function useViewport() {
  const [viewport, setViewport] = useState<Viewport | null>(null)

  function handleViewportChange() {
    const vp = window.innerWidth
    if (vp < 599) {
      setViewport(Viewport.PHONE_ONLY)
    } else if (vp >= 600 && vp < 900) {
      setViewport(Viewport.TABLET_PORTRAIT_UP)
    } else if (vp >= 900 && vp < 1200) {
      setViewport(Viewport.TABLET_LANDSCAPE_UP)
    } else {
      setViewport(Viewport.DESKTOP)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleViewportChange()
      window.addEventListener("resize", handleViewportChange, false)
    }
    return function cleanup() {
      window.removeEventListener("resize", handleViewportChange, false)
    }
  })

  return viewport
}
