import React from "react"
import styled from "styled-components"
import { IIconProps } from "./icon.types"

const StyledIcon = styled.svg`
  display: inline-block;
`

const Icon: React.FC<IIconProps> = ({ icon, ...props }) => {
  return (
    <StyledIcon {...props}>
      <use xlinkHref={`/icons.svg#icon-${icon}`} />
    </StyledIcon>
  )
}

export { Icon }
