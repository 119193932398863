import { createGlobalStyle } from "styled-components"
import tw from "twin.macro"

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Displatter';
    src: url('/fonts/displatter-webfont.woff2') format('woff2'),
        url('/fonts/displatter-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  html {
    font-size: 62.5%;
    ${tw`font-sans`};
  }

  body {
    font-size: 1.6rem;
  }

  address {}

  article {}

  aside {}

  footer {}

  header {}

  main {}

  nav {}

  section {}

  img { width: 100%; display: block; }

  h1, h2 {
    ${tw`font-heading`};
  }

  h3, h4 {
    ${tw`font-sans`};
    font-weight: 900;
  }

  h1 {
    font-size: 7.2rem;
  }

  h3 {
    font-size: 3.6rem;
  }

  p {
    + p {
      margin-top: 1em;
    }
  }

  a {

  }

  ${props => props.theme.media.tablet_portrait_up`

  `}

  ${props => props.theme.media.tablet_landscape_up`
    h1 {
      font-size: 9.6rem;
    }
  `}

  ${props => props.theme.media.desktop_up`

  `}

  ${props => props.theme.media.big_desktop_up`

  `}
`
