import React, { useRef } from "react"
import { createPortal } from "react-dom"
import { OffCanvasInner } from "./OffCanvasInner"
import { IOffCanvasWrapperProps } from "./offcanvas.types"

export const OffCanvas: React.FC<IOffCanvasWrapperProps> = ({
  selector,
  children,
  ...props
}) => {
  const domRef = useRef<Element | null>(
    typeof window !== `undefined` ? document.querySelector(selector) : null
  )

  if (domRef.current === null) return null

  return createPortal(
    <OffCanvasInner {...props}>{children}</OffCanvasInner>,
    domRef.current
  )
}
