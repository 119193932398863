import React, { useState, useEffect } from "react"
import { useSpring, animated } from "react-spring/web.cjs"
import styled from "styled-components"
import { IOffCanvasProps } from "./offcanvas.types"

const StyledOffcanvasInner = styled(props => <animated.div {...props} />)`
  position: fixed;
  z-index: 9500;
  top: ${props => (props.direction === "bottom" ? "0" : 0)};
  bottom: ${props => (props.direction === "top" ? "0" : 0)};
  left: ${props => (props.direction === "right" ? "0" : 0)};
  right: ${props => (props.direction === "left" ? "0" : 0)};
  pointer-events: ${props => (props.show ? "all" : "none")};
`

const OffCanvasInner: React.FC<IOffCanvasProps> = ({
  children,
  direction,
  show,
  ...props
}) => {
  const [initialized, setInitialized] = useState<boolean>(false)
  let transform: [string, string] = ["", ""]

  useEffect(() => {
    // Simple assertion of wether or not the component mounted.
    if (!initialized) setInitialized(true)
  }, [initialized])

  switch (direction) {
    case "top":
      transform = ["translateY(-100%)", "translateY(0%)"]
      break
    case "bottom":
      transform = ["translateY(100%)", "translateY(0%)"]
      break
    case "left":
      transform = ["translateX(-100%)", "translateX(0%)"]
      break
    default:
      transform = ["translateX(100%)", "translateX(0%)"]
  }

  // Using array deconstruction, we can easily switch between transition states
  let [from, to] = transform
  if (!show) [to, from] = transform

  const styleProps = useSpring({
    from: { transform: from },
    to: { transform: to },
    immediate: !initialized,
  })

  return (
    <StyledOffcanvasInner
      direction={direction}
      show={show ? 1 : 0}
      style={styleProps}
      {...props}
    >
      {children}
    </StyledOffcanvasInner>
  )
}

export { OffCanvasInner }
