import { useLayoutEffect } from "react"

export function useLockBodyScroll(toggled: boolean) {
  useLayoutEffect(() => {
    // Prevent scrolling on mount
    document.body.style.overflow = toggled ? "hidden" : "visible"

    return () => {
      document.body.style.overflow = "visible"
    }
  }, [toggled]) // Empty array ensures effect is only run on mount and unmount
}

export function useLockBodyScrollOnMount() {
  useLayoutEffect(() => {
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden"
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = "visible"
    }
  }, []) // Empty array ensures effect is only run on mount and unmount
}
