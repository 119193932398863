import React from "react"
import tw from "twin.macro"
import { IContainerProps } from "./container.types"
import styled from "styled-components"

/**
 * A container that limits the width of its contents.
 * Adjust the size as you please.
 */

const StyledContainer = styled.div<IContainerProps>`
  ${props => props.theme.media.tablet_portrait_up`

  `}

  ${props => props.theme.media.tablet_landscape_up`

  `}

  ${props => props.theme.media.desktop_up`
    ${tw`mx-auto`}
    max-width: ${props.theme.containers.lg}px;
  `}

    @media (min-width: 1300px) {
      ${tw`mx-auto`}
      max-width: ${props => props.theme.containers.xl}px;
  }


  ${props => props.theme.media.big_desktop_up`
    max-width: ${props.theme.containers.xl}px;
  `}
`

export const Container: React.FC<IContainerProps> = ({
  children,
  ...props
}) => {
  return <StyledContainer {...props} tw="">{children}</StyledContainer>
}
