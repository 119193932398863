export type Dictionary<T> = {
  [key: number]: T
}

export type StringDictionary<T> = {
  [key: string]: T
}

export interface IWeekday {
  short: string
  long: string
}

export type UrlDictionary = {
  [index: string]: string
}

export type PageType = "page" | "article" | "course"

export interface IDocumentRef {
  _type: PageType
  slug: {
    path?: string
    current?: string
  }
  parentPage?: IDocumentRef
}

export const WeekDays: Dictionary<IWeekday> = {
  0: { short: "Søn", long: "Søndag" },
  1: { short: "Man", long: "Mandag" },
  2: { short: "Tir", long: "Tirsdag" },
  3: { short: "Ons", long: "Onsdag" },
  4: { short: "Tor", long: "Torsdag" },
  5: { short: "Fre", long: "Fredag" },
  6: { short: "Lør", long: "Lørdag" },
}

export type SocialMediaSite = "facebook" | "linkedin" | "instagram" | "twitter"

export const SocialMediaSites: StringDictionary<string> = {
  instagram: "https://www.instagram.com/",
  facebook: "https://www.facebook.com/",
  linkedin: "https://www.linkedin.com/company/",
  twitter: "https://www.twitter.com/",
}
