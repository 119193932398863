import React from "react"
import styled from "styled-components"
import "twin.macro"
import { Button } from "lib/Button"
import { Icon } from "lib/Icon"
import { IToggleButtonProps } from "../navigation.types"

const StyledNavToggle = styled(Button)`
  background: none;
  padding: 1rem;
`

const NavigationUntoggle: React.FC<IToggleButtonProps> = ({
  onClick,
  children,
  ...props
}) => {
  return (
    <StyledNavToggle onClick={onClick} {...props} tw=" fixed right-8 top-8 text-black hover:text-white">
      {children ? children : <Icon tw="fill-current w-16 h-16" icon="close" />}
    </StyledNavToggle>
  )
}

export { NavigationUntoggle }
