import { css } from "styled-components"
import { BreakpointDictionary } from "./base.types"

const sizes: BreakpointDictionary = {
  tablet_portrait_up: 600,
  tablet_landscape_up: 900,
  desktop_up: 1200,
  big_desktop_up: 1800,
}

// Iterate through the sizes and create a media template
const media: object = Object.keys(sizes).reduce((acc: any, label: string) => {
  acc[label] = (...args: any[]) => {
    const size: number = sizes[label]
    const [first, ...rest] = args
    return css`
      @media (min-width: ${size}px) {
        ${css(first, ...rest)}
      }
    `
  }

  return acc
}, {})

export { media }
