import React from "react"
import "twin.macro"
import styled from "styled-components"
import { IButtonProps } from "./button.types"

const StyledButton = styled.button<IButtonProps>`
  ${({ themeColor, theme }) =>
    themeColor ? theme.brandStyles[themeColor] : theme.brandStyles.default}
  border: none;
  display: inline-block;

  &:focus {
    outline: none;
  }
`

const Button: React.FC<IButtonProps> = ({ children, onClick, ...props }) => {
  return (
    <StyledButton onClick={onClick} {...props} tw="border-none px-8 py-4 inline-flex items-center text-white hover:bg-black transition duration-100 ease-in-out text-3xl font-semibold">
      {children}
    </StyledButton>
  )
}

export { Button }
