import React from "react"
import styled from "styled-components"
import "twin.macro"
import { Button } from "lib/Button"
import { Icon } from "lib/Icon"
import { IToggleButtonProps } from "../navigation.types"

const StyledNavToggle = styled(Button)`
  background: none;
  padding: 1rem;
`

const NavigationToggle: React.FC<IToggleButtonProps> = ({
  onClick,
  children,
  ...props
}) => {
  return (
    <StyledNavToggle onClick={onClick} {...props} tw="md:hidden text-black hover:text-white absolute right-8 top-8">
      {children ? children : <Icon tw="fill-current w-16 h-16" icon="menu" />}
    </StyledNavToggle>
  )
}

export { NavigationToggle }
