import { DefaultTheme } from "styled-components"
import tw from "twin.macro"
import { media } from "theme/base/mediaqueries"
import { containers } from "theme/base/containers"
import { BrandThemeDictionary } from "./base/base.types"

export type ThemeColor =
  | "green"
  | "yellow"
  | "pink"
  | "beige"
  | "creme"
  | "default"

const brandStyles: BrandThemeDictionary = {
  green: tw`bg-green text-white`,
  yellow: tw`bg-yellow text-black`,
  pink: tw`bg-pink`,
  beige: tw`bg-beige`,
  creme: tw`bg-creme`,
  default: tw`bg-white`,
}

export type GridConfig = "oneCol" | "twoCol" | "threeCol"

const gridConfigs: BrandThemeDictionary = {
  oneCol: tw`grid grid-cols-1`,
  twoCol: tw`grid grid-cols-1 sm:grid-cols-2`,
  threeCol: tw`grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3`,
}

export type GapConfig = "slim" | "regular" | "large"

const gapConfigs: BrandThemeDictionary = {
  slim: tw`gap-y-px md:gap-x-px`,
  regular: tw`gap-y-2 md:gap-2`,
  large: tw`gap-y-12 md:gap-x-12`,
}

export const siteTheme: DefaultTheme = {
  media,
  containers,
  brandStyles,
  gridConfigs,
  gapConfigs,
}
