import { ILocationProps } from "components/Location/location.types"
import { ISocialMediaProps } from "./Social/social.types"

export const frb: ILocationProps = {
  key: "frederiksbjerg",
  title: "De Fyrretyve Røvere Pita - Frederiks Allé",
  address: {
    street1: "Frederiks Allé 81",
    street2: "8000 Aarhus C",
    telefon: "+45 25404067",
  },
  openingHours: [
    {
      title: "Alle dage:",
      key: "week",
      daysText: "Åbningstider frokost",
      hours: ["11:30", "15:30"],
    },
    {
      key: "week",
      daysText: "Køkkenet holder lukket mellem",
      hours: ["15:30", "16:30"],
    },
    {
      key: "sunday",
      daysText: "Åbningstider aften",
      hours: ["16:30", "21:30"],
    },
  ],
}

export const brb: ILocationProps = {
  key: "brabrand",
  title: "De Fyrretyve Røvere - Brabrand",
  address: {
    street1: "Louisevej 4",
    street2: "8220 Brabrand",
    telefon: "+45 25404067",
  },
  openingHours: [
    {
      key: "monday",
      days: [1],
      hours: [],
      closed: true,
    },
    {
      key: "sunday",
      days: [2, 3, 4, 5, 6, 0],
      hours: ["16:00", "21:00"],
    },
  ],
}
export const loc3: ILocationProps = {
  key: "trøjborg",
  title: "De Fyrretyve Røvere Pita - Trøjborg",
  address: {
    street1: "Niels Juels Gade 57",
    street2: "8200 Aarhus N",
    telefon: "+45 25404067",
  },
  openingHours: [
    {
      title: "Frokost:",
      key: "mandag",
      // daysText: "Mandag",
      days: [1],
      hours: [],
      closed: true,
    },
    {
      key: "sunday",
      days: [2, 3, 4, 5, 6, 0],
      hours: ["11:30", "15:30"],
    },
    {
      key: "sunday",
      title: "Køkkenet holder lukket mellem:",
      hours: ["15:30", "16:30"],
    },
    {
      title: "Aften:",
      key: "Alle dage:",
      daysText: "Alle dage",
      hours: ["16:30", "21:30"],
    },
  ],
}
export const loc4: ILocationProps = {
  key: "location4",
  title: "De Fyrretyve Røvere Pizza - Frederiksbjerg Torv",
  address: {
    street1: "Frederiksbjerg Torv 1",
    street2: "8000 Aarhus C",
    telefon: "+45 25404067",
  },
  openingHours: [
    {
      key: "mandag",
      days: [1],
      hours: [],
      closed: true,
    },
    {
      key: "sunday",
      days: [2, 3, 4, 5, 6, 0],
      hours: ["16:00", "21:00"],
    },
  ],
}

export const about: string =
  "De Fyrretyve Røvere serverer pita og pizza med et eksotisk, mellemøstligt præg på henholdsvis Frederiksbjerg og i Brabrand i Aarhus."

export const instagram: ISocialMediaProps = {
  key: "insta",
  title: "Følg Røverne på instagram",
  handle: "defyrretyveroevere",
  site: "instagram",
  icon: "instagram",
}

export const facebook: ISocialMediaProps = {
  key: "face",
  title: "Følg Røverne på facebook",
  handle: "defyrretyverovere",
  site: "facebook",
  icon: "facebook",
}
