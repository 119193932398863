import React from "react"
import { Helmet } from "react-helmet"
import { IGoogleFont, IGoogleFontsProps } from "./fonts.types"

function createGoogleFamily(font: IGoogleFont) {
  const title = font.title.split(" ").join("+")
  let weights = font.weights
  if (typeof font.weights !== "string") {
    weights = font.weights.join(";")
  }
  return `family=${title}:wght@${weights}`
}

export const GoogleFonts: React.FC<IGoogleFontsProps> = ({ fonts }) => {
  if (Array.isArray(fonts)) {
    const fontMap: string[] = []
    fonts.map((font: IGoogleFont) => {
      const family: string = createGoogleFamily(font)
      fontMap.push(family)
    })
    const families = fontMap.join("&")
    return (
      <Helmet>
        <link
          href={`https://fonts.googleapis.com/css2?${families}&display=swap?subset=latin,latin-ext`}
          rel="stylesheet"
        />
      </Helmet>
    )
  } else {
    const font: IGoogleFont = fonts
    const family: string = createGoogleFamily(font)
    return (
      <Helmet>
        <link
          href={`https://fonts.googleapis.com/css2?${family}&display=swap?subset=latin,latin-ext`}
          rel="stylesheet"
        />
      </Helmet>
    )
  }
}

// TODO: Figure out how to do this thing with exporting a component that returns createGlobalStyle, to allow flexibility in the future.

/*
function createStaticFontFamily(font: ICustomFont, basePath: string) {
  const base = `/${basePath}/`

  return `
      @font-face {
        font-family: '${font.title}';
        src: url('${base}${font.filename}.woff2') format('woff2'),
            url('${base}${font.filename}.woff') format('woff');
        font-weight: normal;
        font-style: normal;
      }
    `
}

export const CustomFonts: GlobalStyleComponent<
  ICustomFontsProps,
  DefaultTheme
> = ({ fonts, basePath }) => {
  if (Array.isArray(fonts)) {
    const fontMap: string[] = []
    fonts.map((font: ICustomFont) => {
      const family: string = createStaticFontFamily(font, basePath)
      fontMap.push(family)
    })
    return createGlobalStyle`
      ${fontMap.join("")}
    `
  } else {
    const font: ICustomFont = fonts
    const family: string = createStaticFontFamily(font, basePath)
    console.log(family)
    return createGlobalStyle`
      ${family}
    `
  }
} */
