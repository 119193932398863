import React from "react"
import "twin.macro"
import { ISocialMediaProps } from "./social.types"
import { Icon } from "lib/Icon"
import { SocialMediaSites } from "lib/global.types"

export const SocialMediaLink: React.FC<ISocialMediaProps> = ({
  icon,
  title,
  handle,
  site,
  ...props
}) => {
  const siteUrl = SocialMediaSites[site]
  return (
    <a
      href={`${siteUrl}${handle}`}
      rel="noreferrer noopener"
      title={title && title}
      target="_blank"
      {...props}
    >
      <Icon icon={icon} tw="w-16 h-16" />
    </a>
  )
}
